/*
  Mixin for applying ellipsis to any element
*/
.popover-container {
  background-color: white;
  box-shadow: 0px 2px 20px -1px rgba(0, 0, 0, 0.1490196078);
  border-radius: 8px;
  width: 440px;
  max-height: 95vh;
  display: flex;
  flex-direction: column;
}
.popover-container > svg {
  filter: drop-shadow(0 -1px 3px -1px rgba(0, 0, 0, 0.2), 0 2px 1px rgba(0, 0, 0, 0.1));
}

