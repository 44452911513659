@use "@edgetier/client-components/dist/styles/colours";
@use "@edgetier/client-components/dist/styles/variables";

.popover-container {
    background-color: white;
    box-shadow: variables.$popover-box-shadow;
    border-radius: variables.$box-border-radius * 2;
    width: 440px;
    max-height: 95vh;
    display: flex;
    flex-direction: column;

    & > svg {
        filter: drop-shadow(variables.$box-box-shadow);
    }
}
